import React from 'react';
import styled from 'styled-components';

export default function Contact() {

    return (
        <ContactsContainer>
            <h1>Interested in collaborating? </h1>
            <h3>Let me know.</h3>
            <a href="mailto:kimnguyen79@gmail.com" target="_blank" rel="noreferrer" className="fa fa-paper-plane" alt='Email icon'> </a>
            <a href="https://www.linkedin.com/in/kimnguyen79/" target="_blank" rel="noreferrer" className="fa fa-linkedin" alt='Linkin icon'> </a>
            <a href="https://github.com/kimnivore" target="_blank" rel="noreferrer" className="fa fa-github" alt='GitHub icon'> </a>
        </ContactsContainer>

    )
}

const ContactsContainer = styled.div`

margin: 50px auto;
width: 80%;

.fa {
    font-size: 80px;
    text-align: center;
    text-decoration: none;
    border-radius: 5%;
    margin: 50px;
}

.fa-paper-plane, .fa-linkedin, .fa-github {
    background: #faf5f0;
    color: #8d8a85;
}

@media (max-width: 768px) {
    .fa {
        font-size: 50px;
        margin: 30px;
    }
}

@media (max-width: 540px) {
    .fa {
        font-size: 40px;
        margin: 20px;
    }
}
`