import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal';

export default function Portfolio() {

    return (
        <PortfolioContainer>
            <div>
                <h1>Portfolio</h1>
                <h3>Check out some of my recent projects</h3>
            </div>
            <div className='block'>
                <Fade top>
                    <div className='card'>
                        <a href='https://keanes-nft-collection.vercel.app/' target="_blank" rel="noreferrer">
                            <img src={require('../assets/Keane.png')} alt='Keane x Kirby Collection website'/>
                        </a>
                        <div className='right-card'>
                            <div className='link'>
                                <a href='https://keanes-nft-collection.vercel.app/' target="_blank" rel="noreferrer"><h4>KEANE x KIRBY COLLECTION</h4></a>
                                <a href='https://keanes-nft-collection.vercel.app/' target="_blank" rel="noreferrer" className="fa fa-external-link-square"> </a>
                            </div>
                            <p>Keane x Kirby Collection is a Kirby-related NFT, art gallery and memory game application.  </p>
                            <p>A React frontend was implemented, allowing users to connect their Ethereum wallet and mint to OpenSea testnet. Solidity was used to generate on-chain smart contracts to deploy dynamically generated NFTs. Additional features have been added including an art gallery and a flip-card game.</p>
                        </div>
                    </div>
                </Fade>
                <Fade top>
                    <div className='card'>
                        <a href='https://www.underdogdevs.org/' target="_blank" rel="noreferrer">
                            <img src={require('../assets/under.png')} alt='Underdog Devs website'/>
                        </a>
                        <div className='right-card'>
                            <div className='link'>
                                <a href='https://www.underdogdevs.org/' target="_blank" rel="noreferrer"><h4>UNDERDOG DEVS</h4> </a>
                                <a href='https://www.underdogdevs.org/' target="_blank" rel="noreferrer" className="fa fa-external-link-square"> </a>
                            </div>
                            <p>Underdog Devs is a community of software engineers supporting aspiring developers who come from disadvantaged backgrounds or who were formerly incarcerated. </p>
                            <p>As the Frontend developer and UX designer on this collaborative project, I established functionality for program administrators to approve applications and refactored the codebase to implement user interface styling with Ant Design library. </p>
                        </div>
                    </div>
                </Fade>
                <Fade top>
                    <div className='card'>
                        <a href='https://family-recipes-cookbook-frontend.vercel.app/' target="_blank" rel="noreferrer">
                            <img src={require('../assets/cookbook.png')} alt='Family Recipes Cookbook website'/>
                        </a>
                        <div className='right-card'>
                            <div className='link'>
                                <a href='https://family-recipes-cookbook-frontend.vercel.app/' target="_blank" rel="noreferrer" > <h4>FAMILY RECIPES COOKBOOK</h4> </a>
                                <a href='https://family-recipes-cookbook-frontend.vercel.app/' target="_blank" rel="noreferrer" className="fa fa-external-link-square"> </a>
                            </div>
                            <p>Family Recipes Cookbook is an application giving users the ability to create and save family recipes for future generations.</p>
                            <p>Users have the ability to register, login, logout and perform CRUD operations to a backend database which maintains the recipe collection. Frontend design implemented with React, JavaScript, Styled Components and React Bootstrap with CRUD functionality enabled via Axios calls. Backend was structured with Node.js and Express, using Knex to seed and populate data within PostgreSQL backend.</p>
                        </div>
                    </div>
                </Fade>

                <Fade top>
                    <div className='card'>
                        <a href='https://weather-app-kimnivore.vercel.app/' target="_blank" rel="noreferrer">
                            <img src={require('../assets/Weather-app.png')} alt='Weather App website'/>
                        </a>
                        <div className='right-card'>
                            <div className='link'>
                                <a href='https://weather-app-kimnivore.vercel.app/' target="_blank" rel="noreferrer" > <h4>WEATHER TODAY</h4> </a>
                                <a href='https://weather-app-kimnivore.vercel.app/' target="_blank" rel="noreferrer" className="fa fa-external-link-square"> </a>
                            </div>
                            <p>Weather Today is a custom application allowing users to search for the current weather in the city of their choice.</p>
                            <p>The app fetches data from OpenWeather API and Unsplash API, provides the current temperature and other weather data and randomizes a photo related to the selected city.</p>
                        </div>
                    </div>
                </Fade>
            </div>
        </PortfolioContainer>
    )
}

const PortfolioContainer = styled.div`

    width: 80%;
    margin: 50px auto 0 auto;
    h3 {
        margin-bottom: 30px;
    }
    h4 { 
        font-size: 1.8rem; 
        margin: 20px; 
    }
    h4:hover { 
        font-size: 2rem;
        color: #630508;
    }
    a { 
        font-size: 1.5rem; 
        color: #72727e; 
        text-decoration: none;
        margin: auto;
    }
    p { 
        font-size: 1rem;
        margin: 20px;
        text-align: left;
    }
    img { 
        width: 300px;  
        height: auto;
        margin-left: 10px;
        border-radius: 5%;
    }
    .fa {
        color: #8d8a85;
        background-color: #faf5f0;
    }
    .link {
        display: flex;
        align-items: center;
    }
    .card {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 100px 0;
    background-color: #faf5f0;
    }
    .right-card {
        display: flex;
        flex-direction: column;
        /* align-items: flex-start;
        justify-content: flex-start; */
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        width: 95%;
        .card{
            display: flex;
            flex-direction: column;
            padding: 20px 0;
        }
        .right-card {
            align-items: center;
            margin: 20px;
        }
        img {
            width: 50%;
            margin: auto;
        }
        h4 {
            font-size: 1.3rem;
        }

    }

    @media (max-width: 540px) {
        width: 99%;
        img {
            height: 20%;
        }
        h4 {
            font-size: 1.2rem;
        }
    }
`