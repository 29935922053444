import React from 'react';
import styled from 'styled-components';

export default function Home() {
    return (
        <HomeContainer>
            <h1>Kim Nguyen</h1> 
            <p>Full Stack Web Developer</p>
            <p>Based in San Francisco</p>
            <p>Open to new opportunities</p>
            <a href="mailto:kimnguyen79@gmail.com" target="_blank" rel="noreferrer" className="fa fa-paper-plane" alt='Email icon'> </a>
            <a href="https://www.linkedin.com/in/kimnguyen79/" target="_blank" rel="noreferrer" className="fa fa-linkedin" alt='LinkedIn icon'> </a>
            <a href="https://github.com/kimnivore" target="_blank" rel="noreferrer" className="fa fa-github" alt='GitHub icon'> </a>
        </HomeContainer>
    )
}

const HomeContainer = styled.div`

    width: 80%;
    margin: 20% auto;
    text-align: left;

   h1 {
       font-size: 6rem;
   }
   p{
       font-size: 1rem;
       margin: 20px;
   }
   .fa {
    font-size: 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 5%;
    margin: 25px;
}

.fa-paper-plane, .fa-linkedin, .fa-github {
    background: #faf5f0;
    color: #8d8a85;
}

@media (max-width: 540px) {
    h1{
        font-size: 2.5rem;
    }
}
`