import React, { useState } from 'react';
import logo from '../assets/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink, 
  NavbarToggler,
  Collapse
} from 'reactstrap';


export default function Navigation() {

    const [isOpen, setIsOpen]  = useState(false);
    return (
        <NavContainer>
            <Navbar light expand='md'>
                <NavbarBrand href='/'>
                    <img
                        src={logo}
                        alt='kn logo'
                    />
                </NavbarBrand>
                <NavbarToggler onClick={() => {setIsOpen(!isOpen) }} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className='mr-auto' navbar>
                        <NavItem className='NavItem'>
                            <NavLink className='link' href='/'>Home</NavLink>
                            <NavLink className='link' href='/about'>About</NavLink>
                            <NavLink className='link' href='/portfolio'>Portfolio</NavLink>
                            <NavLink className='link' href='/contact'>Contact</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </NavContainer>
    )
}

const NavContainer = styled.div`
    height: auto;

    img {
        height: 100px;
    }

    .NavItem {
        display: flex;
        justify-content: space-between;
    }
`