import React from 'react';
import styled from 'styled-components';



export default function About() {
    return (
        <AboutContainer>
            <h1>About me</h1>
            <img 
                src={require('../assets/kim.png')}
                alt='Kim standing in front of a brick wall in Brooklyn'
            />  
            <div className='about' >
                <p>I'm a web developer interested in optimizing user experiences and creating applications that will help to improve the lives of others.</p>
                <p>I recently graduated from a 6-month, intensive full stack web development program which has given me the technical knowledge and skills to build amazing products and has allowed me to pursue my dreams of working in tech. </p>

                <p>I have an MBA with over 12 years experience in product and project management.</p>
                <p>Based in San Francisco. Feel free to take a look at some of my recent <a href='/portfolio'>projects</a> and let's <a href='/contact'>connect</a>. </p>
            </div>
            <div className='skills'>
                <h2>Skills</h2>
                <p>React - Node - JavaScript - Redux - HTML - CSS - SQL - Express - Jest - Cypress - Heroku - Vercel - PostgreSQL </p>
                <p>Project Management - Product Management - Strategy - Compliance</p>
            </div>
            
           
        </AboutContainer>
    )
}

const AboutContainer = styled.div`   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 50px auto;

    img{
        width: 30%;
        margin: auto;
        margin-bottom: 30px;
        border-radius: 5%;
    }
    h1{
        margin-bottom: 30px;
    }
   
    h2{
        text-align: left;
        margin-bottom: 10px;
    }
    a {
        text-decoration: underline;
        color: #2e3440; 
    }
    a:hover {
        color: #630508;
    }
    .about, .skills{
        font-size: 1rem;
        margin: 20px;
        width: 80%;  
    }
    .about {
        text-align: left;
    }

    .skills p{
        text-align: center;
    }
    
    @media (max-width: 768px) {
       width: 90%;
        img {
            width: 40%;
        }
    }
    @media (max-width: 580px) {
       width: 90%;
        p {
            font-size: 1rem;
        }
        img {
            width: 60%;
        }
    }

`